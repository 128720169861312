import { useState, useEffect } from 'react';
import { download } from '../../utils';
import './index.scss';
// 图片
import gxb from '../../static/images/gxb.png';
import android from '../../static/images/android.png';
import apple from '../../static/images/apple.png';
import logo from '../../static/images/logo.png';
import mobileModel from '../../static/images/pc/mobile_model.png';
import mobileModel1 from '../../static/images/pc/mobile_model_1.png';
import mobileModel2 from '../../static/images/pc/mobile_model_2.png';
import mobileModel3 from '../../static/images/pc/mobile_model_3.png';
import mobileModel4 from '../../static/images/pc/mobile_model_4.png';

function PC() {
  const list = [
    { image: mobileModel1, title: '丰富资讯', desc: ['新鲜硬核资讯，赛事热点，', '心爱球队的推荐方案一个不错过'] },
    { image: mobileModel2, title: '专家精讲', desc: ['每日比赛众多，常常选错?', '正开专家给你挑出最有价值的竞彩组合'] },
    { image: mobileModel3, title: '数据资料', desc: ['比赛赛况, 全方位多层次分析', '独家历史数据积累，专业赛事分析'] },
    { image: mobileModel4, title: '清爽阅读', desc: ['设计更加简洁与轻量化，', '减少视觉干扰，让目光停留在内容本身'] }
  ]
  const [show, setShow] = useState(false);
  const [qrcode, setQrcode] = useState('');

  useEffect(() => {
    const url = window.location.href;
    fetch(`http://zk.dashenliao.com/user/get_app_qrcode?url=${url}`, { method: "GET" }).then(response => {
      return response.json();
    }).then(data => {
      setQrcode(data.data || "");
    });
  }, []);

  function handleShow(e: any) {
    e.stopPropagation();
    setShow(!show);
  }

  return (
    <div className='pc' onClick={() => { setShow(false) }}>
      {/* 顶部 */}
      <div className='header'>
        <img className='logo min' src={logo} />
        <span>正开体育世界</span>
      </div>

      {/* 主体 */}
      <div className="content">
        <div className='page'>
          {/* 左侧内容 */}
          <div className='info'>
            {/* 上部分 */}
            <div className="title">
              <div>正开体育世界</div>
              <div>大神推单，红单不难</div>
            </div>
            <div className='introduce'>
              <div>资深专家团队，十余年行业经验，多角度解读比</div>
              <div>赛信息，全方位了解赛事数据，助您长红！</div>
            </div>
            {/* 下部分 */}
            <div className='QR-code-box'>
              <div className='btn-box'>
                <div className='btn android' onClick={() => { download(false) }}>
                  <img className='icon' src={android} />
                  <span>Android下载</span>
                </div>

                {/* <div className='btn apple' onClick={() => { download(true) }}>
                  <img className='icon' src={apple} />
                  <span>iPhone下载</span>
                </div> */}
              </div>
              <img className='QR_code' src={qrcode} />
            </div>
          </div>
          {/* 手机 */}
          <img className='mobile-model' src={mobileModel} />
        </div>

        <div className='page'>

          <div className='list-box'>
            {
              list.map((item, index) =>
                <div className='item' key={`list-item-${index}`}>
                  <img className='image' src={item.image} />
                  <span className='title'>{item.title}</span>
                  {
                    item.desc.map((desc, descIndex) =>
                      <span className='desc' key={`list-desc-${index}-${descIndex}`}>{desc}</span>
                    )
                  }
                </div>
              )
            }
          </div>

          {/* 底部 */}
          <div className='footer'>
            {/* 备案 */}
            <a className='inline' href='https://beian.miit.gov.cn/#/Integrated/index'>
              <img className='icon' src={gxb} />
              <span>湘ICP备2023023537号-1</span>
            </a>
            {/* 联系我们 */}
            <a className='contact' onClick={(e) => (handleShow(e))}>联系我们</a>
            {show ? <img className='customer-service-pc' src="https://dashenliaohangzhou.oss-cn-hangzhou.aliyuncs.com/hongliaobaov_2//qianyue/20230212/167620922263e8ec46d247e.png" alt="联系客服" /> : null}
            {/* 公司名称 */}
            <span>湖南即派配送有限公司</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PC;
