import { inWeixin, isIos } from 'web-launch-app';
// 组件
import { toast } from 'react-toastify';

const { AndroidDownload, IOSDownload } = require('../config');

export function download(iso: boolean = isIos) {
    if (inWeixin) {
        if (iso)
            toast("请点右上角，在Safari浏览器中打开", { position: 'top-center' });
        else
            toast("请点右上角，在浏览器中打开", { position: 'top-center' });
    } else {
        if (iso)
            window.location.href = IOSDownload;
        else
            window.location.href = AndroidDownload;
    }
}