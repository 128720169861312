import { useState } from 'react';
import { download } from '../../utils';
import './index.scss';
// 扩展
// import copy from 'copy-to-clipboard';

// 组件
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// 图片
import mobileModel from '../../static/images/mobile/mobile_model.png';
import logo from '../../static/images/logo.png';
import android from '../../static/images/android.png';
import apple from '../../static/images/apple.png';

function Mobile() {
  const [show, setShow] = useState(false);

  function handleShow(e: any) {
    e.stopPropagation();
    setShow(!show);
  }

  return (
    <div className='mobile' onClick={() => { setShow(false) }}>
      <div className='title'>大神推单，红单不难</div>
      <div className='tips'>资深专家团队，多角度解读比赛</div>
      <img className='logo' src={logo} />
      <div className='btn-box'>
        <div className='btn' onClick={() => { download(false) }}>
          <img className='icon' src={android} />
          <span>安卓下载</span>
        </div>

        {/* <div className='btn' onClick={() => { download(true) }}>
          <img className='icon' src={apple} />
          <span>IOS下载</span>
        </div> */}
      </div>
      <img className='mobile-model' src={mobileModel} />
      {/* 底部 */}
      {show ? <img className='customer-service' src="https://dashenliaohangzhou.oss-cn-hangzhou.aliyuncs.com/hongliaobaov_2//qianyue/20230212/167620922263e8ec46d247e.png" alt="联系客服" /> : null}
      <div className='internet-content-provider'>
        <a href='https://beian.miit.gov.cn/#/Integrated/index'>
          <img src="" />
          <span>湘ICP备2023023537号-1</span>
        </a>
        <span className='contacter' onClick={(e) => (handleShow(e))}>联系我们</span>
      </div>
      {/* 提示 */}
      <ToastContainer />
    </div>
  );
}

export default Mobile;
